import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {referralBalance } from '../Request/Wallets';
import {referralsHistory} from '../Request/Histories';
import { QRCode } from 'react-qrcode-logo';
import EarningSlides from '../Investment/EarningSlides';

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
const getDate = (date) => new Date(date).toDateString();


const Referrals = () => {
let user = localStorage.getItem('delox-user');
if (user != null) {
    user = JSON.parse(user);
}
const refLink = window.location.origin + '/invitation?referral='+encodeURIComponent(user.name)
let myReferralHistory = referralsHistory();
const [pendingreferrals, setPendingreferral] = useState(0);
const [activereferral, setActivereferral] = useState(0);
const [completedreferral, setcompletedreferral] = useState(0);
const [copied, setCopied] = useState(false);
const [buttonText, setbuttonText] = useState(null);


useEffect(() => {
    document.title = 'REFERRAL';
    myReferralHistory = referralsHistory();
    let pendingNum = 0;        
    let activeNum = 0;        
    let completedNum = 0;  

    myReferralHistory.forEach(referral => {
            
            if (referral.status === 'approved') {
                activeNum ++;
            }
            if (referral.status === 'completed') {
                completedNum ++;
            }
            if (referral.status === 'pending') {
                pendingNum ++;
            }
            if (referral.status === 'waiting approval') {
                pendingNum ++;
            }
        
    });
    
    setActivereferral(activeNum);
    setcompletedreferral(completedNum);
    setPendingreferral(pendingNum);
    
    if(window && window.init_template){
        window.init_template();
    }  
}, [myReferralHistory, activereferral, completedreferral, pendingreferrals]);
  return (
    <div className="container px-sm-4 px-0" style={{marginTop: '40px'}}>
        <EarningSlides/>

        <div className="card card-style px-0 bg-white">
            <div className="content text-center d-flex flex-column justify-content-center ">
            <h4 className='mb-2 text-dark'>Scan the QR code below to visit referral link or click the button below to copy</h4>  
            <div className='shadow-sm p-2 align-self-center' style={{width: 'fit-content'}}>
            <QRCode qrStyle="dots" value={refLink} size={200} logoImage='https://res.cloudinary.com/dzxyvdq14/image/upload/v1667985773/quantumlogoappicon.png' />
            </div>           
                
            </div>
            <CopyToClipboard onCopy={() => {setbuttonText('referral link copied!');
              setTimeout(() => {
                setbuttonText(null);
              }, 2000);}} text={refLink}>
                <button className="btn gradient-green btn-sm m-2 tra-orange-red-hover rounded-pill">
                  {buttonText !== null? buttonText: 'copy referral link'}
                </button>
                </CopyToClipboard>
        </div>
        <div className="card card-style">
   <div className="content mb-0">
       <div className="tabs tabs-pill" id="lend-customers">
           <div className="tab-controls rounded-m p-1 overflow-visible">
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Declined</a>
           </div>
           <div className="mt-3"></div>
           {/* <!-- Tab Bills List --> */}
        <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!pendingreferrals > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No pending referral records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myReferralHistory.map(referral =>(
                        referral.status === 'pending' &&
                        <Link key={referral.id}  data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(referral.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(referral.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-red-dark">{referral.invited.email}</h4>
                                <p className="mb-0 font-12 opacity-50">{referral.status}</p>
                            </div>
                        </Link>
                    ))                
                               
                }
            </div>                                          
    </div>

    <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!activereferral > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No active referral records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myReferralHistory.map(referral =>(
                        referral.status === 'approved' &&
                        <Link key={referral.id}  data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(referral.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(referral.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-green-dark">{referral.invited.email}</h4>
                                <p className="mb-0 font-12 opacity-50">{referral.status}</p>
                            </div>
                        </Link>
                    ))                
                            
                }
            </div>                                          
    </div>
    
    {/* <!-- Tab Custom Payments--> */}
    <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
        <div className="content mt-0 mb-0">
            {!completedreferral > 0 ?
            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className="align-self-center no-click">
                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                        </div>
                        <div className="align-self-center no-click">
                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                No completed referrals record avaialable
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            :
                myReferralHistory.map(referral =>(
                    referral.status === 'completed' &&
                    <Link key={referral.id}  data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{getAmount(referral.amount)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(referral.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-theme">{referral.invited.email}</h4>
                            <p className="mb-0 font-12 opacity-50">{referral.status}</p>
                        </div>
                    </Link>
                ))                
            
            }
        </div>                   
        </div>
       </div>
   </div>
</div>
    </div>
  )
}

export default Referrals